//flow
import React from "react";
import List from "./list";
import Detail from "./detail";
import { Router } from "@reach/router";
import { PrivateRoute, PublicRoute,Editor } from "../../page-components/posts";
import { Layout } from "../../components";

const Component = () => {
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/posts/edit/:postId" component={Editor} />
        <PublicRoute path="/posts">
          <List path="/" />
          <List path="/filters/:filters" />
          <Detail path="/:postId" />
        </PublicRoute>
      </Router>
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component